<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-card class="mt-5">
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{$vuetify.lang.t('$vuetify.equipment.list')}}
        <v-spacer></v-spacer>

        <v-btn
          :to="{ name: 'equipmentCreate'}"
          class="mx-1"
          color="primary"
          dark
          small
          @click="handleAddClick"
        >
          入库
        </v-btn>
        <v-btn
          :to="{ name: 'equipmentCreate', params: { patch: 'tab-batch' }}"
          class="mx-1"
          color="primary"
          dark
          small
          @click="handleAddClick"
        >
          批量入库
        </v-btn>
        <v-btn
          class="mx-1"
          color="primary"
          dark
          small
          @click="handleDistribute"
        >
          发放
        </v-btn>
        <v-btn
          class="mx-1"
          color="error"
          dark
          small
          @click="handleDeleteClick"
        >
          删除
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="equipments"
          item-key="id"
          show-select
          single-select
          hide-default-footer
        >
          <template
            v-slot:item.category="{ item }"
          >
            {{ $vuetify.lang.t(`$vuetify.equipmentCategory.${snakeToCamel(item.templateCategory.toLowerCase())}`) }}
          </template>

          <template
            v-slot:item.templateName="{ item }"
          >
            {{ item.templateName }}
          </template>

          <template
            v-slot:item.patient="{ item }"
          >
            <span
              v-if="item.patientId"
            >
              {{ item.patientName }}
            </span>
            <span
              v-else
            >
              -
            </span>
          </template>


          <template
            v-slot:item.name="{ item }"
          >
            {{ item.equipmentTemp.name }}
          </template>

          <template
            v-slot:item.state="{ item }"
          >
            {{ $vuetify.lang.t(`$vuetify.equipmentState.${item.state.toLowerCase()}`) }}
          </template>

          <template
            v-slot:item.actions="{ item }"
          >

            <v-btn
              v-if="item.state === 'GRANT'"
              color="info"
              x-small
              text
              fab
              @click="handleRecyclingItem(item)"
            >
              <v-icon
                small
              >
              mdi-restart
              </v-icon>
            </v-btn>

          </template>

        </v-data-table>

        <v-pagination
          v-model="queryParams.params.page"
          :length="queryParams.params.pageCount"
        />
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $vuetify.lang.t('$vuetify.common.warning') }}
        </v-card-title>
        <v-card-text
          v-if="selected.length > 0"
        >
          {{ $vuetify.lang.t('$vuetify.message.deleteConfirm') }}
          <Strong>
            {{ selected[0].deviceId }}
          </Strong>
          ?
        </v-card-text>
        <v-card-text
          v-else
        >
          {{ $vuetify.lang.t('$vuetify.message.needSelectedOne') }}
        </v-card-text>
        <v-card-actions
          v-if="selected.length > 0"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.cancel') }}
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="handleDeleteDialog"
          >
            {{ $vuetify.lang.t('$vuetify.common.delete') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else
        >
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="recyclingDialog"
      persistent
      max-width="290"
    >
      <v-card
        v-if="editItem"
      >
        <v-card-title class="headline">
          {{ $vuetify.lang.t('$vuetify.common.warning') }}
        </v-card-title>
        <v-card-text
        >
          {{ $vuetify.lang.t('$vuetify.message.recyclingConfirm') }}？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="recyclingDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.cancel') }}
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="handleRecyclingDialog"
          >
            {{ $vuetify.lang.t('$vuetify.common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import { snakeToCamel } from '@/utils';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },

    data () {
      return {
        selected: [],
        deleteDialog: false,
        recyclingDialog: false,
        editItem: null,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        }
      }
    },

    computed: {

      ...mapGetters({
        equipments: 'equipment/equipments'
      }),

      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.equipment.deviceId'),
            align: 'start',
            sortable: false,
            value: 'deviceId',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.category'),
            align: 'start',
            sortable: false,
            value: 'category',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.name'),
            align: 'start',
            sortable: false,
            value: 'templateName',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.patient'),
            align: 'start',
            sortable: false,
            value: 'patient',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.storeDate'),
            align: 'start',
            sortable: false,
            value: 'storeDate',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipment.state'),
            align: 'start',
            sortable: false,
            value: 'state',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.actions'),
            align: 'start',
            sortable: false,
            value: 'actions',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.equipmentQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
            })
         }
      }
    },


    created () {

      let params = Object.assign({}, this.queryParams.params)
      params.page--
      this.equipmentQuery({
        params: params,
        data: {}
      }).then( (res) => {
        this.queryParams.params.pageCount = res.totalPages
      })

    },

    methods: {

      ...mapActions({
        equipmentQuery: 'equipment/query',
        equipmentDelete: 'equipment/delete',
        equipmentRecycling: 'equipment/Recycling'
      }),

      snakeToCamel,

      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.equipmentQuery({params: params, data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      },

      handleDistribute  () {
        this.$router.push( {name: 'equipmentDistribute'})
      },

      handleAddClick () {
        console.log('add')
      },

      handleDeleteClick () {
        this.deleteDialog = true
      },

      handleDeleteDialog () {
        this.equipmentDelete(this.selected[0]).then( () => {
          this.deleteDialog = false
        })
      },

      handleRecyclingItem (item) {
        this.editItem = item
        this.recyclingDialog = true
      },

      handleRecyclingDialog () {
        this.equipmentRecycling({id: this.editItem.id, params: { pt_id: this.editItem.patient.id }}).then( () => {
          this.recyclingDialog = false
        })
      }
    }
  }
</script>
